import {
  AuthToken,
  SignIn,
} from "../../packages/lz-graphql/idm/external/generated/idm-external-graphql";

export function getAuthData(): SignIn | AuthToken | null {
  // TODO: Use WebUI SessionStorageService - Will require refactor once merged in
  const authDataJson = sessionStorage.getItem("authData");
  const authData: SignIn | AuthToken | null = authDataJson
    ? JSON.parse(authDataJson)
    : authDataJson;
  return authData;
}

export function clearAuthData() {
  sessionStorage.setItem("authData", null);
  sessionStorage.setItem("authToken", null); // TODO: Remove. Currently still being used internally in webui which affects routing
}
